<template>
	<div>

	</div>
</template>

<script>
export default {
	props: {
		select: {
			type: String,
			default: '',
		},
		selectOptions: {
			type: Array,
			default: () => [
				'Website',
				'Webshop',
				'Wordpress',
				'PHP',
			],
		},
	},
};
</script>

<style lang="scss" scoped></style>
