<template>
	<!-- Projects start -->
	<section class="pt-20 sm:pt-28" ref="projecten">
		<!-- Projects grid title start -->
		<div class="text-center">
			<p
				class="text-2xl sm:text-5xl font-semibold mb-3 text-ternary-dark dark:text-ternary-light"
			>
				{{ projectsHeading }}
			</p>
			<p class="text-md sm:text-xl text-gray-500 dark:text-ternary-light">
				{{ projectsDescription }}
			</p>
		</div>
		<!-- Projects grid title end -->

		<!-- Filter and search projects start -->
		<div class="mt-10 sm:mt-16">
      <ProjectsFilter/>
		</div>
		<!-- Filter and search projects end -->

		<!-- Projects grid start -->
		<div class="grid grid-cols-1 sm:grid-cols-3 mt-6 sm:gap-10">
			<ProjectSingle
				v-for="project in filteredProjects"
				:key="project.id"
				:project="project"
			/>
		</div>
		<!-- Projects grid end -->
	</section>
	<!-- Projects end -->
</template>

<script>
import feather from 'feather-icons';
import ProjectsFilter from './ProjectsFilter.vue';
import ProjectSingle from './ProjectSingle.vue';

export default {
	components: { ProjectSingle, ProjectsFilter },
	data: () => {
		return {
			projectsHeading: 'Mijn Projecten',
			projectsDescription:
				'Enkele van de projecten waaraan ik heb gewerkt of die ik met succes heb afgerond.',
			selectedProject: '',
			searchProject: '',
			projects: [
				{
					id: 1,
					title: 'Flekto.nl',
					category: 'Website',
					img: require('@/assets/images/flekt.png'),
				},
        {
          id: 2,
          title: 'Jokasport.nl',
          category: 'Website',
          img: require('@/assets/images/brands/jokasport.png'),
        },
        {
          id: 3,
          title: 'Meprint.nl',
          category: 'Webshop',
          img: require('@/assets/images/brands/meprint.png'),
        },
        {
          id: 4,
          title: 'Rikkimode.nl',
          category: 'Webshop',
          img: require('@/assets/images/brands/rikkimode.png'),
        },
        {
          id: 5,
          title: 'Sports-supplier.nl',
          category: 'Webshop',
          img: require('@/assets/images/brands/sports_supplier.svg'),
        },
        {
          id: 6,
          title: 'Felixflos.nl',
          category: 'Webshop',
          img: require('@/assets/images/brands/felixflos.png'),
        },
			],
			// publicPath: process.env.BASE_URL,
		};
	},
	computed: {
		filteredProjects() {
			if (this.selectedProject) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				return category.includes(this.selectedProject);
			});
		},
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<style scoped></style>
